/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
// Import Bootstrap
//@import "~bootstrap/scss/bootstrap";
// Import Font Awesome
//@import "~@fortawesome/fontawesome-free/scss/fontawesome";
// Import Formus Labs styles
@import "dashboard";