/*
 * General
 */

#sys-versions {
  float: right;
  margin: 5px 10px 0 0;
  font-size: 10px;

  span {
    display: block;
  }
}

.checkbox-container-unstyled {

  ul {
    list-style: none;
    padding: 0;

    label {
      font-weight: normal;
    }
  }
}

/*
 * Top navigation
 */

.navbar-default .navbar-nav {

  > .open > a {
    color: #FFFFFF;
    background-color: #428bca;

    &:hover, &:focus {
      color: #FFFFFF;
      background-color: #428bca;
    }
  }

  > li > .dropdown-menu {
    padding: 0;

    .divider {
      margin: 0;
    }

    > li > a {
      padding: 6px 20px;
    }

    li.user-name-item {
      background-color: #428bca;

      .user-name, .user-name:hover {
        color: #FFFFFF;
        background-color: #428bca;
      }
    }
  }
}

.notification-bar {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    float: left;
    position: relative;
    display: block;
    visibility: hidden;

    &.active {
      visibility: visible;
      cursor: pointer;
    }

    > a {
      padding: 15px 13px;
      line-height: 20px;
      display: block;
      position: relative;
      text-decoration: none;
      font-size: 16px;
    }
  }
}

/*
 * Dashboard page
 */
.dashboard-page {
  .popover-title {
    float: right;
    background-color: transparent;
    border-color: transparent;
  }

  .action-btn, .download-popup-btn {
    margin-right: 10px;
    min-width: 14px;
    min-height: 14px;
    display: inline-block;
    cursor: pointer;
  }

  .panel {
    .panel-heading {
      padding-top: 1px;

      h4 {
        text-align: center;
      }
    }
  }
}

/*
 * User edit page
 */

.user-assistants-list, .user-sales-reps-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  max-height: 400px;
  max-width: 250px;
  overflow-y: auto;
  border: 1px solid #eee;
  min-height: 100px;
  background-color: #eee;

  li {
    border-top: 1px solid #ddd;
    padding: 7px 10px;
    position: relative;
    display: block;
    background-color: white;

    .add-user, .remove-user {
      cursor: pointer;
    }

    &:first-child {
      border-top: none;
    }
  }
}

/*
 * Main content
 */

#project-form-status {
  margin-bottom: 20px;
}

.segmentation {
  .sidebar {
    width: 100px;
    padding: 0;
    z-index: 9999999;
    bottom: auto;
    left: -100px;
    overflow: visible;
    display: none;
    -webkit-box-shadow: 1px 2px 3px 0px rgba(82, 82, 82, 1);
    -moz-box-shadow: 1px 2px 3px 0px rgba(82, 82, 82, 1);
    box-shadow: 1px 2px 3px 0px rgba(82, 82, 82, 1);

    #main-in-menu-btn {
      left: 101px;
      text-align: center;
      background-color: #353636;
      color: #D6D6D6;
      width: 50px;
      padding: 10px;
      position: absolute;
      cursor: pointer;

      &:hover {
        background-color: #2E2D2D;
      }

      .fa {
        font-size: 28px;
      }
    }

    .nav > li > a {
      padding: 15px 20px;
    }

    .nav-sidebar {
      margin: 0;
    }
  }
}

.viewer-container {
  #viewing-mode, .viewer-bottom-group-buttons {
    position: absolute;
    height: 52px;
    width: auto;
    bottom: 3px;
    text-align: left;
    color: #eee;
    font: 12px "Lucida Grande", sans-serif;
    z-index: 999999;
    border: 1px solid #666;
    left: 50%;
    margin-left: -75px;
    background: #191919;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        width: 50px;
        height: 50px;
        padding: 8px;
        float: left;
        border-left: 1px solid #666;

        &:hover {
          background: #2E2D2D;
          cursor: pointer;
        }

        &.active {
          background: #494848;
        }

        &:first-child {
          border-left: none;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  #baseplate-screws, #rotator-cuff-acromion {
    margin-left: -130px;
  }

  #glenoid-planes {
    margin-left: -185px;
  }

  #measurements-table-container {
    width: 100%;

    .table-inner {
      margin: auto auto 15px auto;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 350px;
      padding: 0 20px 15px 20px;
    }
  }

  #measurements-table {
    margin-bottom: 0;

    thead tr th {
      padding-top: 20px;
      border-top: none;
    }

    tbody tr td {
      text-transform: capitalize;
    }
  }

  #toggle-measurements-table {
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

body.fullscreen {
  .sidebar {
    top: 0px !important;
  }

  .viewer-container {
    overflow: visible !important;

    #gotoPreviewBtn {
      position: fixed;
    }

    #back-to-box {
      bottom: -55px;
      position: fixed !important;

      #gotoPreviewBtn {
        position: static;
      }
    }
  }
}

.segmentation.container-fluid {
  margin: 0;
  padding: 0;

  .viewer-container {
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;

    #process-message span.message a {
      color: #00f;
      text-decoration: underline;
      font-weight: bold;
    }

    #viewer-alignment-container {
      margin: 0;

      #viewer-message span a {
        color: #00f;
        text-decoration: underline;
        font-weight: bold;
      }

      #viewer-loading-overlay {
        margin: 0;
      }

      #viewer {
        margin: 0;
      }
    }

    #back-to-box {
      position: absolute;
      z-index: 999999;
      left: 60px;
      bottom: -97px;
      width: 145px;

      .back-to-icon {
        font-size: 30px;
        width: 42px;
        padding: 5px;
        display: inline-block;
        line-height: 1;
        background-color: #337ab6;
        color: white;
        text-align: center;
        cursor: pointer;
        float: left;

        &:hover {
          background-color: #286090;
        }
      }

      .back-to-btns {
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 5px;
        float: left;
        background-color: white;
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

        .btn {
          float: left;
          clear: both;
          margin: 5px;
        }
      }
    }
  }
}

#viewer-menu {
  ul {
    li {
      &#main-menu-btn {
        text-align: center;

        .fa {
          font-size: 28px;
        }
      }
    }
  }
}

.viewer-widget, #stem-selection-widget, #stem-adjust-widget, #cup-selection-widget, #cup-cs-widget, #stem-cs-widget {
  position: absolute;
  width: 260px;
  height: auto !important;
  top: 105px;
  text-align: left;
  color: #eee;
  font: 12px 'Lucida Grande', sans-serif;
  z-index: 999999;
  border: 1px solid #000000;

  .viewer-widget-body, .stem-selection-widget, .stem-adjust-widget, .cup-selection-widget, .cs-widget, .reverse-glenoid-widget {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
    float: left;
    background: #1a1a1a;

    .title {
      background: black url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==) 6px 10px no-repeat;
      text-transform: capitalize;
      height: 27px;
      padding-left: 16px;
      line-height: 27px;
      cursor: pointer;
      width: 100%;
      float: left;

      &.closed {
        background-image: url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlGIWqMCbWAEAOw==);
      }
    }

    .viewer-widget-row, .slider-row, .trigger-row, .trigger-row, .stem-option-row, .stem-option-btn-row,
    .cup-option-row, .cup-option-btn-row, .baseplate-option-row, .baseplate-option-btn-row {
      width: 100%;
      float: left;
      display: inline-block;
      background: #1a1a1a;
      padding: 0 5px;

      &:nth-child(2) {
        padding-top: 5px;
      }

      &.stem-adjust-changed, &.cup-adjust-changed {
        color: red;
        text-align: center;
        display: none;
        padding-top: 5px;
      }

      #align-mode {
        padding: 0;
        height: 20px;
        font-size: 12px;
      }

      .property-name {
        text-transform: capitalize;
        float: left;
        width: 55%;
        margin: 6px 0;
        cursor: move;
      }

      .object-slider-container {
        padding: 0 0 5px 0;
        width: 100%;
        float: left;

        .object-slider {
          float: left;
          width: 100%;
          height: 18px;
          border-radius: 0;

          .ui-slider-handle {
            display: none;
          }

          .ui-widget-header {
            border-radius: 0;
            background: #428BCA;
          }
        }
      }

      .input-group {
        padding-top: 2px;
        width: 45%;

        input {
          width: 80%;
          float: right;
          margin-top: 0;
          border-radius: 0;
        }

        .input-group-addon {
          padding: 0 3px 0 2px;
          border: 0;
          color: #bbb;
          background-color: #303030;
          border-radius: 0;
        }
      }

      input {
        float: right;
        width: 25%;
        background-color: #303030;
        border: 0;
        color: #428BCA;
        height: 19px;
        margin-top: 3px;
        padding: 0px 3px;
        text-align: right;
      }

      .property-unit {
        float: right;
        width: 20%;
        text-align: center;
        margin-top: 6px;
        padding-left: 5px;
      }

      .btn {
        margin: 10px 0;
      }
    }

    .slider-row {
      .object-slider-container {
        padding: 0px 17px 6px 17px;
        position: relative;
      }
    }

    .trigger-row, .stem-option-row, .cup-option-row {
      padding-bottom: 5px;

      &:nth-child(3) {
        padding-top: 5px;
      }

      label {
        text-transform: capitalize;
        font-weight: normal;
        width: 100%;
        cursor: pointer;
        padding: 5px 0 3px 5px;
        line-height: 17px;
        margin-bottom: 0;

        &:hover {
          background-color: #2E2D2D;
        }
      }

      input[type="checkbox"], input[type="radio"] {
        width: auto;
        margin: -2px 5px 0 5px;
      }
    }

    .column-name {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid #666;
      background: #1a1a1a;
      padding: 6px 5px 4px 5px;
      font-weight: bold;
      // cursor: move;
      line-height: 14px;

      .column-name-title {
        display: inline-block;
        width: auto;
      }

      .column-name-option {
        float: right;
        padding: 0 0 0 5px;
        border-left: 1px solid #666;

        &.column-name-option-exclude {
          color: red;
          margin-left: 5px;
        }
      }
    }

    .title-tool {
      cursor: inherit;
      background-image: none;
    }

    .trigger-row-tool {
      padding: 15px 20px;

      .property-name {
        width: 50%;
        cursor: inherit;
      }

      .property-unit {
        width: 50%;
        text-align: right;

        .tool-conversion, .tool-result {
          display: inline-block;
        }
      }
    }

    .numeral-input-container {
      display: none;

      &.active {
        display: table;
      }
    }

    .numeral-container {
      padding-top: 2px;
      width: 45%;
      position: relative;
      display: table;
      border-collapse: separate;

      .acid-numeral {
        background-color: #303030;
        border: 0;
        color: #bbbbbb;
        height: 19px;
        padding: 0px 3px;
        text-align: right;
        float: right;
        line-height: 1.5;
        font-size: 14px;
      }
    }
  }

  &.readonly, &.widget-for-screws_align {
    .object-folder .slider-row .object-slider-container {
      display: none;
    }
  }
}

#cup-cs-widget {
  left: 55px;
  top: 620px;
}

#stem-cs-widget {
  left: 55px;
  top: 610px;
}

#glenoid-cs-widget {
  left: 297px;
  top: 595px;
}

#glenoid-planes-info-widget {
  left: 50%;
  top: 70%;
  margin-left: -140px;

  .viewer-widget-row {
    padding-bottom: 5px;

    .property-colormap {
      width: 25px;
      height: 25px;
      float: left;
      margin-right: 5px;
    }

    .property-value {
      display: inline-block;
      margin: 6px 0;
    }
  }
}

#rotator-cuff-acromion-info-widget {
  left: 50%;
  top: 70%;
  margin-left: -140px;

  .viewer-widget-row {
    padding-bottom: 5px;
    .property-value {
      display: inline-block;
      margin: 6px 0;
    }
  }
}

.reverse-glenoid-container #object-info {
  left: 55px;
  top: 595px;
}

.reverse-humerus-container #object-info {
  left: 55px;
  top: 515px;
}

#object-list {
  top: 120px;
  left: auto;
  right: 5px;
}

#baseplate-screws-widget {
  top: 5px;
  left: 320px;
}

#stem-selection-widget, #cup-selection-widget, #reverse-glenoid-widget {
  left: 55px;
  top: 5px;

  .stem-option-info, .cup-option-info, .baseplate-option-info {
    padding-bottom: 5px;

    table {
      background-color: #1a1a1a;
      margin: 0;
      border-top: 1px solid #666;
      border-bottom: 1px solid #666;

      tbody {
        tr {
          // border-top: 1px solid #ddd;
          // padding: 5px 5px 0 0;
          &:nth-child(1) {
            border: none;
          }

          td {
            border: none;
            padding: 5px 0px 0px 9px;

            &.table-item-title {
              font-weight: bold;
              min-width: 123px;
              // padding-top: 5px;
              // padding-bottom: 0;
            }

            &.stem-info-value, &.cup-info-value {
              // padding-left: 15px;
            }
          }
        }

        &:last-child {
          td {
            padding-bottom: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .stem-option-lists select {
    padding: 0;
    height: 20px;
    font-size: 12px;
  }

  .highlight-red {
    background: red;
  }

  .highlight-pink {
    background: #ffbaba;
  }
}

#cup-selection-widget, #reverse-glenoid-widget {
  #slider-cup-offset .ui-slider-range {
    background: rgb(0, 255, 0);
  }

  #slider-si-shift .ui-slider-range {
    background: rgb(255, 0, 0);
  }

  #slider-ap-shift .ui-slider-range {
    background: rgb(0, 0, 255);
  }

  .cup-angles-ignored {
    color: red;
    text-align: center;
  }

  .toggle_coverage_visibility {
    padding: 0 5px;
    line-height: 1.1;
    font-size: 15px;
  }
}

#reverse-glenoid-widget {
  .baseplate-option-info table {
    border-top: none;
  }

  fieldset {
    border: 1px solid #666666;
    padding: 4px 4px;
    margin: 0 2px 10px;

    legend {
      font-size: 14px;
      margin: 0;
      color: #e9e9e9;
      width: 100%;
      cursor: pointer;
      &:hover {
        color: #c5e7ff;
      }

      i {
        float: right;
        font-size: 18px;
      }
    }

    ul {
      display: inline-block;
      padding: 0;
      list-style: none;
      .btn {
        font-size: 14px;
      }
    }
  }
}

// All the widgets
.viewer-widget, #stem-selection-widget, #stem-adjust-widget, #cup-selection-widget, #cup-cs-widget, #stem-cs-widget,
#object-info, #object-list, #case-info, #model-list, #implant-list, #object-transparency {
  border: 1px solid #202020;
}

.viewer-widget .widget-dropdown {
  padding: 0;
  height: 20px;
  font-size: 12px;
}

.annotation {
  margin-left: 10px;
}

#viewer-menu {
  ul {
    li {
      img {
      }

      &.active {
        background-color: #494848;
      }

      &.disabled {
        background-color: #A4A4A4;
      }

      &#viewerTools-add-screw, &#viewerTools-annotate {
        .screw-types, .annotate-options {
          .pelvis-screw {
          }

          .pelvis-screw, .spine-screw, .annotate-option {
            background-color: #000000;

            &:hover {
              background-color: #060606;
            }

            &.active {
              background-color: #494848;
            }

            img {
            }

            span {

            }
          }
        }
      }

      &#viewerTools-annotate {
        .screw-types, .annotate-options {
          .annotate-option {
            &:first-child {
            }
          }
        }
      }
    }
  }
}

// Custom slider controls
.slider-manual-control {
  position: absolute;
  top: 0;
  font-size: 14px;
  cursor: pointer;
  z-index: 1000000;
  border: 1px solid #aaa;
  height: 18px;
  width: 18px;
  text-align: center;

  &:hover {
    background-color: #494848;
  }
}

.slider-control-minus {
  left: 0;
}

.slider-control-plus {
  right: 0;
}

// Custom styles for pips slider
.ui-slider-pips {
  &:not(.ui-slider-disabled) {
    .ui-slider-pip:hover .ui-slider-label {
      color: #e6e6e6;
    }
  }

  .ui-slider-pip {
    font-size: 1em;
    color: #eee;
  }

  .ui-slider-pip-inrange {
    color: #eee;

    .ui-slider-line {
      background: #999;
    }
  }

  [class*=ui-slider-pip-selected] {
    color: #eee;
    font-weight: bold;
    font-size: 1.2em;

    .ui-slider-line {
      background: #999;
    }
  }
}

/*
 * Styling overwrites
 */

// Bootstrap toggle
.toggle {
  &.btn {
    min-height: 32px;
    min-width: 75px;
  }
}

// @media (max-width: 1024px) {
//   .sidebar {
//     width: 90px;
//     padding: 10px;
//     .nav > li > a {
//       padding: 10px;
//       font-size: 12px;
//     }
//     .nav-sidebar {
//       margin-right: -11px;
//       margin-left: -10px;
//     }
//   }

//   .main {
//     &.viewer-container {
//       margin-left: 0px;
//       width: 100%;
//       padding-right: 20px;
//       padding-left: 20px;
//       padding-bottom: 10px;

//       .page-header {
//         z-index: 9999;
//         position: fixed;
//         top: 10px;
//         left: calc(50% - 115px);
//         font-size: 20px;
//       }
//     }
//   }
// }
