/*
  TODO: WARNING: this variable is hacked in to get this file to compile.
*/
$btn-primary-bg:                 #fff !default;
$gray-base: #000 !default;
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
}

/* Helper classes */

.center {
  text-align: center;
}

/*
 * Global add-ons
 */

.panel-heading {
  padding-bottom: 0px;

  .nav-tabs {
    border-bottom: 0;
  }
}

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */

#navbar {
  text-align: center;

  .page-header-title {
    display: inline-block;
    font-size: 22px;
    margin-top: 13px;
  }
}

.navbar-fixed-top {
  border: 0;
}

.navbar-brand {
  padding: 10px 15px;

  img {
    height: 100%;
  }
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
}

/*
 * Powered By
 */

.powered-by {
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 280px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  margin-left: -380px;
  padding: 0px 10px;
  z-index: 9999;

  img {
    height: auto;
    width: 100%;
  }
}

/*
 * User active box
 */

#user-active-box {
  display: none;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #aaa;
  position: absolute;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  padding: 6px 8px 8px;
  box-sizing: border-box;
  text-align: center;
  z-index: 99999999;
  top: 70px;

  button, .btn {
    width: 80px;
  }

  .message {
    font-size: 24px;
    margin-bottom: 15px;
  }
}


.layout-plain, .layout-rich {
  padding-top: 0;

  .logo-big {
    display: inline-block;

    a {
      display: block;
    }
  }

  #footer {
    border-top: 1px solid #ccc;
    padding-top: 10px;

    .logo {
      width: 170px;
      float: right;

      a {
        display: block;
      }

      img {
        height: auto;
        width: 100%;
      }
    }
  }
}

body.layout-rich {
  padding-top: 50px;

  #footer {
    .logo {
      width: 200px;
    }
  }
}

/*
 * Main content
 */

.main {
  padding: 20px 20px 40px 20px;
}

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.main .page-header {
  margin-top: 0;
  font-size: 26px;
}


/*
 * Placeholder dashboard ideas
 */

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}

.placeholders h4 {
  margin-bottom: 0;
}

.placeholder {
  margin-bottom: 20px;
}

.placeholder img {
  display: inline-block;
  border-radius: 50%;
}


/*
 * Custom Formus Labs styles ****************************
 */

.main .page-header {
  text-align: center;
}

.navbar-default {
  background-color: white;
  border-bottom: 1px solid $gray-lighter;

  .profile-icon {
    border-left: 1px solid $gray-lighter;
  }
}

.sidebar {
  background-color: white;
  text-align: center;
  padding-top: 0;
  // max-width: 150px;
  .nav {
    > li {
      > a {
        padding: 20px;

        i {
          font-size: 26px;
        }
      }
    }
  }
}

.page-header {
  border-bottom: 0;
}

.loading-overlay {
  text-align: center;
  color: #FFFFFF;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  display: none;
  background-color: rgba(42, 42, 42, 0.9);
  z-index: 9999999999;
  margin: 0px;

  .spinner {
    display: block;
    opacity: 1;
    font-size: 50px;
    margin: 20% auto auto auto;
  }

  .loading-text {
    font-size: 24px;
    display: block;
  }

  .loading-model {
    font-size: 16px;
    display: block;
    padding-top: 15px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}

.form-group.required, .form-group.group-required {
  // .control-label:after { 
  //   content:"*";
  //   color:red;
  //   margin-left: 3px;
  // }
  span {
    color: red;
    font-size: 80%;
  }
}

.form-control {
  &.error {
    border: 2px solid red;
  }
}

input.error, label.error, fieldset.error {
  border: 2px solid red;
}

legend {
  width: inherit;
  border-bottom: none;
  padding: 0 10px;
  margin-bottom: 10px;
}


/*
 * Dashboard page
 */

.feedback-table-container {
  max-height: 250px;
  overflow-y: auto;
  display: inline-block;
  width: 100%;

  .feedback-table-description {
    max-width: 300px;
  }

  .screenshots-container .thumbnail {
    margin-bottom: 0;
    display: inline-block;
  }
}


/*
 * Project Assets page
 */

.errorlist {
  margin: 0;
  padding: 0;
  list-style: none;
  color: red;
}

.project-assets-page {
  form {
    .form-inline {
      margin-bottom: 10px;
    }
  }

  .list-group {
    .fa-spinner {
      margin-left: 5px;
    }

    .delete-file {
      cursor: pointer;
      background-color: #fd5d5d;
      color: white;
      border-radius: 4px;

      &:hover {
        background-color: red;
      }
    }
  }
}

#show-upload-modal {
  .modal-body {
    .upload-item-container {
      display: inline-block;
      margin-top: 10px;
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid #eee;

      &:nth-child(2) {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }

      img {
        width: 100%;
        // float: left;
        // margin-right: 20px;
      }

      .radio {
        display: inline-block;
        min-width: 33%;
      }
    }
  }
}

/*
 * Project Segmentation page
 */

.segmentation-page {
  #segmentation-form {
    .panel-heading {
      padding-bottom: 0px;

      .nav-tabs {
        border-bottom: 0;
      }
    }
  }
}

.segmentation-main-slice-container {
  display: inline-block;
  float: none;

  .main-slice-container {
    width: 100%;
  }

  canvas {
    // display: none !important;
  }
}

.segmentation-main-slice {
  position: relative;

  ul {
    padding: 0 25px 0 0;
    overflow: hidden;

    li {
      list-style: none;

      &.position-slice {
        height: 0px;
      }

      img {
        width: 100%;
      }
    }
  }

  .slice-slider {
    position: absolute;
    top: 0;
    right: -10px;
  }
}

.point-circle {
  width: 1px;
  height: 1px;
  position: absolute;
  display: none;
  border-radius: 50%;
  z-index: 99999;
  border-width: 3px;

  .circle-rim {
    display: block;
    border: 1px solid white;
    margin-left: -7px;
    margin-top: -7px;
    border-radius: 50%;
    padding: 6px;
  }

  &:hover {
    cursor: help;
  }
}

.point .btn {
  border-width: 2px;
}

.point-circle.point-1, .point-1 .btn {
  border: solid #f00; /* red */
}

.point-circle.point-2, .point-2 .btn {
  border: solid #008000; /* green */
}

.point-circle.point-3, .point-3 .btn {
  border: solid #00f; /* blue */
}

.point-circle.point-4, .point-4 .btn {
  border: solid #ff0; /* yellow */
}

.point-circle.point-5, .point-5 .btn {
  border: solid #800080; /* purple */
}

.point-circle.point-6, .point-6 .btn {
  border: solid #f5deb3; /* wheat */
}

.point-circle.point-7, .point-7 .btn {
  border: solid #639; /* rebeccapurple */
}

.point-circle.point-8, .point-8 .btn {
  border: solid #ff6347; /* tomato */
}

.segmentation-points {
  padding-left: 30px;

  fieldset {
    margin-bottom: 20px;

    ul {
      padding: 10px 0 0 0;

      li {
        list-style: none;
        margin-bottom: 10px;

        .btn {
          padding: 3px 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

.segmentation-thumbnail-slice {
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
  }

  .slice-position {
    position: absolute;
    background-color: red;
    z-index: 9999;

    &.slice-coronal-axial {
      height: 1px;
      top: 0;
      left: 0;
      right: 0;
    }

    &.slice-coronal-sagittal, &.slice-sagittal-axial, &.slice-sagittal-coronal {
      width: 1px;
      left: 0;
      top: 0;
      bottom: 0;
    }

    &.slice-axial-sagittal, &.slice-axial-coronal {
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

#segmentationProgress, #modelProgress, #itemProgress {
  margin: 15px 0 0 0;
}

/*
 * Project Viewer page
 */


.header-container {
  display: inline-block;
  width: 100%;
  text-align: center;

  .page-header {
    display: inline-block;
  }

  .project-versions-container {
    display: inline-block;
    margin-left: 20px;
  }
}

#viewer-alignment-container {
  position: relative;

  &.fullscreen {
    z-index: 9999;
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;

    #viewer {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
    }
  }

  #viewer-loading-overlay {
    position: absolute;
    display: block;
    background-color: #2A2A2A;
    margin: 0px 13px 20px 13px;
  }
}

.viewer-container #viewer {
  border: 1px solid $gray-lighter;
  margin: auto 0 15px 15px;
  overflow: hidden;
}

#viewer-cube-container-wrapper {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 100px;
  height: 100px;
  z-index: 999;

  #viewer-cube-container {
    position: relative;
    width: 100%;
    height: 100%;

    #viewer-cube {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }
}

#viewer-info-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  border: 1px solid #428BCA;
  background-color: rgba(249, 249, 249, 1);
  cursor: pointer;
  z-index: 1000001;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  font-family: serif;
  font-size: 15px;
  line-height: 18px;
}


.annotation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  margin-left: -5px;
  margin-top: -26px;
  padding: 0 12px 6px 12px;
  width: 200px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  /*border-radius: .5em;*/
  font-size: 12px;
  line-height: 1.2;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;

  &:after, &:before {
    right: 100%;
    top: 15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0.8);
    border-width: 10px;
    margin-top: 0px;
  }

  &:before {
    border-color: rgba(0, 0, 0, 0);
    /*border-right-color: rgba(0, 0, 0, 0.8);*/
    border-width: 10px;
    margin-top: -15px;
  }

  .annotation-update-splash {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    z-index: 999;
    margin-left: -12px;
    display: none;

    p, i {
      top: 50%;
      left: 50%;
      position: absolute;
      margin-left: -25px;
      margin-top: 5px;
    }

    i {
      font-size: 25px;
      margin-left: -13px;
      margin-top: -24px;
    }
  }

  .annotation-inner {
    position: relative;
    margin-top: 30px;
    padding-top: 7px;
    border-top: 1px solid #666;

    .annotation-by {
      top: -21px;
      left: 0;
      position: absolute;
      font-weight: bold;
    }

    .anno-delete, .anno-edit, .anno-hide {
      position: absolute;
      top: -25px;
      border: 1px solid transparent;
      padding: 2px;

      &:hover, &.active {
        border: 1px solid #666;
        background-color: rgba(150, 150, 150, 0.32);
        cursor: pointer;
      }
    }

    .anno-delete {
      right: 36px;
    }

    .anno-edit {
      right: 16px;
    }

    .anno-hide {
      right: 0px;
    }

    .form-group {
      padding: 0;
      margin-bottom: 7px;

      textarea {
        margin-bottom: 5px;
      }
    }
  }
}

.landmark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  margin-left: 7px;
  margin-top: -13px;
  padding: 6px;
  // width: 200px;
  color: #fff;
  background: rgba(50, 50, 100, 0.6);
  /*border-radius: .5em;*/
  // border: 1px solid #c2e1f5;
  font-size: 12px;
  line-height: 1.2;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;

  &:after, &:before {
    right: 100%;
    top: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: transparent;
    border-right-color: rgba(50, 50, 100, 0.6);
    border-width: 8px;
    margin-top: -7px;
  }

  &:before {
    border-color: transparent;
    border-width: 10px;
    margin-top: -9px;
  }

  .landmark-inner {
    // position: relative;
    // margin-top: 30px;    
    // padding-top: 7px;
    // border-top: 1px solid #666;
    .landmark-note {
      margin: 0;
      text-transform: capitalize;
    }

    .landmark-hide {
      position: absolute;
      top: -25px;
      border: 1px solid transparent;
      padding: 2px;

      &:hover, &.active {
        border: 1px solid #666;
        background-color: rgba(150, 150, 150, 0.32);
        cursor: pointer;
      }
    }

    .landmark-hide {
      right: 0px;
    }
  }
}

.dyn-infobox {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  margin-left: 7px;
  margin-top: -13px;
  padding: 6px;
  // width: 200px;
  color: #fff;
  background: rgba(50, 50, 100, 0.6);
  /*border-radius: .5em;*/
  // border: 1px solid #c2e1f5;
  font-size: 12px;
  line-height: 1.2;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;

  &:after, &:before {
    right: 100%;
    top: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: transparent;
    border-right-color: rgba(50, 50, 100, 0.6);
    border-width: 8px;
    margin-top: -7px;
  }

  &:before {
    border-color: transparent;
    border-width: 10px;
    margin-top: -9px;
  }

  .dyn-infobox-title {
    padding-bottom: 3px;
    font-weight: bold;
  }

  .dyn-infobox-text {
    border-top: 1px solid white;
    padding-top: 5px;

    p {
      margin-bottom: 3px;
    }
  }
}

.fullscreen .annotation {
  margin-left: 10px;
}


#keyboard-shortcuts {
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
  border: 1px solid #428BCA;
  background-color: rgba(249, 249, 249, 0.9);
  padding: 10px;
  font-size: 12px;
  z-index: 1000000;
  display: none;
}

#object-info, #viewer-tools, #object-list, #case-info, #model-list, #implant-list, #object-transparency, #object-position-rotation {
  position: absolute;
  width: 240px;
  height: auto !important;
  top: 105px;
  right: 25px;
  text-align: left;
  color: #eee;
  font: 12px 'Lucida Grande', sans-serif;
  z-index: 999999;
  border: 1px solid #000000;

  .object-folder, .object-list-folder, .tool-folder, .case-info-folder, .model-list-folder, .object-transparency, .object-position-rotation {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
    float: left;

    .title {
      background: black url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==) 6px 10px no-repeat;
      text-transform: capitalize;
      height: 27px;
      padding-left: 16px;
      line-height: 27px;
      cursor: pointer;
      width: 100%;
      float: left;

      &.closed {
        background-image: url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlGIWqMCbWAEAOw==);
      }
    }

    .slider-row, .trigger-row {
      width: 100%;
      float: left;
      display: inline-block;
      background: #1a1a1a;
      padding: 0 5px;

      &:nth-child(2) {
        padding-top: 5px;
      }

      .property-name {
        text-transform: capitalize;
        float: left;
        width: 55%;
        margin: 6px 0;
        cursor: move;
      }

      .object-slider-container {
        padding: 0 0 5px 0;
        width: 100%;
        float: left;

        .object-slider {
          float: left;
          width: 100%;
          height: 18px;
          border-radius: 0;

          .ui-slider-handle {
            display: none;
          }

          .ui-widget-header {
            border-radius: 0;
            background: #428BCA;
          }
        }
      }

      .input-group {
        padding-top: 2px;
        width: 45%;

        input {
          width: 80%;
          float: right;
          margin-top: 0;
          border-radius: 0;
        }

        .input-group-addon {
          padding: 0 3px 0 2px;
          border: 0;
          color: #bbb;
          background-color: #303030;
          border-radius: 0;
        }
      }

      input {
        float: right;
        width: 25%;
        background-color: #303030;
        border: 0;
        color: #428BCA;
        height: 19px;
        margin-top: 3px;
        padding: 0px 3px;
        text-align: right;
      }

      .property-unit {
        float: right;
        width: 20%;
        text-align: center;
        margin-top: 6px;
        padding-left: 5px;
      }

      .btn {
        margin: 10px 0;
      }
    }

    .slider-row {
      .object-slider-container {
        padding: 0px 17px 6px 17px;
        position: relative;

        &.catstack-slider-container {
          padding: 0px 42px 6px 17px;

          .slider-control-plus {
            right: 25px;
          }

          .toggle-catstack {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 14px;
            cursor: pointer;
            z-index: 1000000;
            height: 18px;
            width: 18px;
            text-align: center;
          }
        }
      }
    }

    .trigger-row {
      padding-bottom: 4px;

      &:nth-child(3) {
        padding-top: 5px;
      }

      label {
        text-transform: capitalize;
        font-weight: normal;
        width: 100%;
        cursor: pointer;
        padding: 5px 0 3px 5px;
        line-height: 17px;
        margin-bottom: 0;

        &:hover {
          background-color: #2E2D2D;
        }
      }

      input[type="checkbox"], input[type="radio"] {
        width: auto;
        margin: -2px 5px 0 5px;
      }
    }

    .column-name {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid #666;
      background: #1a1a1a;
      padding: 6px 5px 4px 5px;
      font-weight: bold;
      // cursor: move;
      line-height: 14px;

      .column-name-title {
        display: inline-block;
        width: auto;
      }

      .column-name-option {
        float: right;
        padding: 0 0 0 5px;
        border-left: 1px solid #666;

        &.column-name-option-exclude {
          color: red;
          margin-left: 5px;
        }
      }
    }

    .title-tool {
      cursor: inherit;
      background-image: none;
    }

    .trigger-row-tool {
      padding: 15px 20px;

      .property-name {
        width: 50%;
        cursor: inherit;
      }

      .property-unit {
        width: 50%;
        text-align: right;

        .tool-conversion, .tool-result {
          display: inline-block;
        }
      }
    }
  }

  &.readonly, &.widget-for-screws_align {
    .object-folder .slider-row .object-slider-container {
      display: none;
    }
  }
}

#object-list {
  left: 85px;
  right: auto;

  .object-list-folder {
    .column-name {
      .column-name-option.column-name-option-exclude {
        display: none;
      }
    }

    .trigger-row {
      label {
        width: 100%;
      }
    }
  }

  &.exclude-present {
    .object-list-folder {
      .column-name {
        .column-name-option.column-name-option-exclude {
          display: block;
          position: relative;
        }
      }

      .trigger-row {
        label {
          width: 65%;
        }

        input.excluded_inputs {
          margin-right: 10%;
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }
}

#case-info {
  right: auto;
  left: 85px;
  top: 15px;

  .case-info-folder {
    .column-name {
      border-top: 1px solid #666;
      border-bottom: 0;

      &:first-child {
        border-top: 0;
      }
    }
  }

  img {
    width: 100%;

    &.color-spectrum {
      height: 25px;
    }
  }

  .info-name {
    display: block;
    margin-bottom: 3px;
  }

  .spectrum-high {
    display: inline-block;
    font-weight: normal;
    font-size: 11px;
  }

  .spectrum-low {
    float: right;
    font-weight: normal;
    font-size: 11px;
  }

  .bone_loss {
    float: right;
  }
}

#viewer-tools {
  display: none;
  bottom: 0;
  top: auto;
  bottom: 50px;
  right: 15px;
}

#undo-redo-container {
  position: absolute;
  bottom: 10px;
  left: 70px;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      padding: 5px;
      cursor: pointer;
      color: gray;
      float: left;
      margin-right: 7px;
      font-size: 25px;

      &.active {
        color: #1a1a1a;
      }

      &:first-child {
        // border: 0;
      }
    }
  }
}

#object-menu, #viewer-menu {
  display: none;
  padding: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: left;
  z-index: 999999;
  background-color: #1a1a1a;
  color: #D6D6D6;
  border: 1px solid black;
  font: 12px 'Lucida Grande', sans-serif;
  opacity: 0.9;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      padding: 5px 10px;
      border-bottom: 1px solid black;
      cursor: pointer;

      &:hover {
        background-color: #2E2D2D;
      }

      &:first-child {
        // border: 0;
      }
    }
  }
}

#viewer-menu {
  top: 0px;
  left: 0px;
  bottom: 0;
  width: 50px;
  display: block;

  ul {
    li {
      padding: 10px;

      img {
        width: 100%;
        height: 100%;
      }

      &.active {
        background-color: #494848;
      }

      &.disabled {
        background-color: #A4A4A4;
      }

      &#viewerTools-add-screw, &#viewerTools-annotate, &#viewerTools-landmarks, &#viewerTools-measurements {
        position: relative;

        .screw-types, .annotate-options, .landmark-regions {
          position: absolute;
          left: 100%;
          top: -1px;
          height: 80px;
          display: block;
          width: 240px;

          .pelvis-screw, .landmark-region {
            float: left;
          }

          .pelvis-screw, .spine-screw, .annotate-option, .landmark-region {
            display: block;
            text-align: center;
            background-color: #1a1a1a;
            height: 80px;
            width: 80px;
            min-width: 80px;
            border-bottom: 0;
            border-left: 1px solid black;

            &:hover {
              background-color: #2E2D2D;
            }

            &.active {
              background-color: #494848;
            }

            img {
              width: 85%;
              height: auto;
            }

            span {
              display: block;
              font-size: 11px;
              margin-top: 2px;
            }
          }

          .landmark-region {
            border-top: 1px solid black;

            span {
              font-size: 10px;
            }
          }
        }
      }

      &#viewerTools-annotate, &#viewerTools-landmarks {
        position: relative;

        .screw-types, .annotate-options, .landmark-regions {
          position: absolute;
          left: 100%;
          top: -1px;
          display: block;
          height: auto;
          width: auto;

          .annotate-option {
            height: auto;
            width: 94px;

            &:first-child {
              border-bottom: 1px solid black;
            }
          }
        }
      }

      &#viewerTools-landmarks, &#viewerTools-measurements {
        padding: 0;

        .tool-main-icon {
          padding: 7px;
        }

        .landmark-regions {
          .landmark-region {
            border-bottom: 1px solid black;
          }
        }
      }
    }
  }

  #viewer-help {
    position: absolute;
    bottom: 19px;
    font-size: 30px;
    left: 10px;
    cursor: pointer;
  }

  #viewer-version {
    position: absolute;
    bottom: 5px;
    left: 9px;
    color: #C8C8CA;
    font-size: 10px;
    z-index: 9999999;
  }
}

#viewer-message {
  position: absolute;
  top: 3px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  text-align: center;
  z-index: 9999999;
  opacity: 1;
  display: none;
}

#navigation-modal {
  .close {
    opacity: 0.5;
    color: white;

    &:hover {
      opacity: 0.9;
    }
  }

  .modal-content {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;

    .modal-body {
      text-align: center;

      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: inline-block;
        width: 100%;

        li {
          width: 33.33%;
          float: left;

          span {
            font-size: 20px;
            display: block;
          }

          img {
            width: 43px;
            margin: 15px 0;
          }
        }
      }
    }
  }
}

#stats {
  width: 80px;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
  opacity: 0.9;
  z-index: 10000;
}

.project-manage-page {
  .action-btn, .download-popup-btn {
    margin-right: 10px;
    min-width: 14px;
    min-height: 14px;
    display: inline-block;
    cursor: pointer;
  }

  .popover-title {
    float: right;
    background-color: transparent;
    border-color: transparent;
  }

  .popup-body {
    label.checkbox, label.radio {
      padding-left: 30px;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        background-color: whitesmoke;
      }
    }
  }
}

/*
 * Model Preview page
 */

.small-circle {
  padding-top: 2px;
  background-color: #5b5b5b;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  color: white;
  text-align: center;
  margin: 5px 15px 0 15px;
}

#reviseComponents {
  margin-right: 20px;
}

#revise-prj-modal, #request-approval-prj-modal {
  .modal-body {
    label.checkbox, label.radio {
      padding-left: 30px;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        background-color: whitesmoke;
      }
    }
  }
}

#delete-prj-modal {
  .modal-dialog {
    margin-top: 100px;
  }

  span {
    font-weight: bold;
  }
}

/*
 * Login page
 */

.login-page {
  margin-top: 5%;

  .logo-login {
    display: block;
    margin: auto;
  }

  .alert {
    float: none;
    margin: 20px auto auto auto;

  }

  .main {
    float: none;
    background-color: #007fb5;
    margin: 80px auto 20px auto;
    box-shadow: 0 0 10px 1px rgba(101, 101, 101, 0.64);
    color: white;

    h3 {
      text-align: left;
    }

    .link {
      color: blue;

      &:hover {
        color: blue;
      }
    }
  }
}

/*
 * Signup page
 */

.signup-page {
  margin-top: 5%;

  .logo {
    display: block;
    margin: auto;
  }

  .main {
    float: none;
    margin: auto;
    background-color: #007fb5;
    margin: 40px auto 20px auto;
    box-shadow: 0 0 10px 1px rgba(101, 101, 101, 0.64);
    color: white;

    .form-group {
      &.required {
        span {
          color: #ff6e00;
        }
      }
    }

    h3 {
      text-align: left;
    }

    .link {
      color: blue;

      &:hover {
        color: blue;
      }
    }

    label {
      cursor: pointer;
    }

    .g-recaptcha > :first-child {
      margin: auto;
    }
  }
}

/*
 * Manage Users page
 */

.users-manage-page {
  .users-table {
    margin-bottom: 40px;

    .action-btn {
      margin-right: 10px;
    }
  }
}

#delete-user-modal {
  #modal_user_name {
    font-weight: bold;
  }
}

.user-roles, .user-status {
  text-transform: capitalize;
}

table, table.table-striped {
  tbody {
    tr.user-status-verified {
      background-color: #19e0ff;

      &:hover {
        background-color: #12d4f3;
      }
    }

    tr.user-status-unverified {
      background-color: yellow;

      &:hover {
        background-color: #edff00;
      }
    }

    tr.user-status-inactive {
      background-color: #ff6e00;

      &:hover {
        background-color: #ff6000;
      }
    }
  }
}


.users-edit-page {
  form {
    padding-top: 20px;
  }
}


/*
 * Captcha form
 */
form {
  .g-recaptcha > :first-child {
    margin: auto;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    width: 90px;
    padding: 10px;

    .nav > li > a {
      padding: 10px;
      font-size: 12px;
    }

    .nav-sidebar {
      margin-right: -11px;
      margin-left: -10px;
    }
  }

  .main {
    &.viewer-container {
      margin-left: 90px;
      width: calc(100% - 90px);
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 10px;

      .page-header {
        z-index: 9999;
        position: fixed;
        top: 10px;
        left: calc(50% - 115px);
        font-size: 20px;
      }
    }
  }
}
